import { computed, Injectable, Signal, signal, WritableSignal, } from "@angular/core";

@Injectable({
    providedIn: "root",
})
export class LoaderService {
    apiCount: number = 0;
    private readonly _isLoading: WritableSignal<boolean> = signal<boolean>(false);
    readonly isLoading: Signal<boolean> = computed(() => this._isLoading());

    start(): void {
        if (this.apiCount === 0) {
            this._isLoading.set(true);
        }
        this.apiCount++;
    }

    stop(): void {
        this.apiCount--;
        if (this.apiCount === 0) {
            this._isLoading.set(false);
        }
    }
}
